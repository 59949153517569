.container{margin-left: 10%;width: 80%;}
.container > h1{text-align: left;}
.container > div,.pledges > div > div.pledge > div,.pledges > div > div.orphan > div > div,.pledges > div > div.widow > div > div, .pledges > div > div.donated > div > div{display: flex;flex-direction: row;text-align: left;}
.container > div > label, .container > div > span, .pledges > div > div.pledge > div > label, .pledges > div > div.pledge > div > span, .pledges > div > div.orphan > div > div > label, .pledges > div > div.widow > div > div > label, .pledges > div > div.orphan > div > div > span, .pledges > div > div.widow > div > div > span, .pledges > div > div.donated > div > div > label, .pledges > div > div.donated > div > div > span{width: 50%;padding: 4px;}
.container > div > input,.container > div > select, .pledges > div > div.orphan > div > div > input , .pledges > div > div.widow > div > div > input, .pledges > div > div.orphan > div > div > select , .pledges > div > div.widow > div > div > select, .pledges > div > div.donated > div > div > input,.pledges > div > div.pledge > div > input,.pledges > div > div.pledge > div > select {width: 50%;}
.container > div > label, .pledges > div > div.orphan > div > div > label, .pledges > div > div.widow > div > div > label, .pledges > div > div.donated > div > div > label,.pledges > div > div.pledge > div > label{font-weight: 600;}
.container > div.orphan, .container > div.widow, .container > div.donated,.container > div.pledges{flex-direction: column;}
.container > button:nth-of-type(1){background-color: forestgreen;}
.container > button{color: white;font-weight: bold;letter-spacing: 0.8px;border-style: none;border-radius: 5px;background-color: red;padding:8px 12px;margin: 12px 0px;font-size: 14px;width: 100%;}
.modal-container{position: fixed;width: 100vw;height: 100vh;top: 0px;left: 0px;display: flex;justify-content: center;align-items: center;background-color: rgba(0,0,0,0.5);}
.modal-container > div{width: 50vw;background-color: white;padding: 24px;}
.modal-container > div > div{display: flex; flex-direction: column;}
.modal-container > div > div > button{font-size: 14px; margin-top: 12px; padding: 8px;letter-spacing: 0.8px;font-weight: bold;border-style: none;border-radius: 5px;color: white;}
.modal-container > div > div > button:nth-of-type(1){background-color: forestgreen;}
.modal-container > div > div > button:nth-of-type(2){background-color: red;}
.orphan > div > button, .widow > div > button, .donated > div > button, .remove-pledge{background-color: rgba(255, 0, 0, 0.2);border-style: none;border: 1px solid red;width: 100%;color: red;padding:8px 12px;margin: 12px 0px 0px 0px;font-size: 14px;border-radius: 5px;letter-spacing: 0.8px; font-weight: bold;}
.orphan > button, .widow > button, .donated > button, .add-pledge {background-color: rgba(34,139,34,0.2);border: 1px solid forestgreen;color: forestgreen;padding:8px 12px;margin: 12px 0px 0px 0px;font-size: 14px;border-radius: 5px;letter-spacing: 0.8px; font-weight: bold;width: 100%;}
.add-pledge{margin:0px 12px;width: calc(100% - 24px);}
.pledge-container{padding: 12px;border: 1px solid gray;border-radius: 5px; margin-bottom: 12px;}
.pledge-container:nth-of-type(odd){background-color: rgba(255, 0, 0, 0.1);}
