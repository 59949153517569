.container{padding: 32px 64px;}
.container > h2{text-align: left;}
.container > div > h3{text-align: left;}
.container > div{text-align: left;}
.container > div > a{padding: 12px 8px; justify-content: space-between;display: flex; text-decoration: none;}
.container > div > a:nth-of-type(odd){background-color: rgba(255, 0, 0, 0.1);}
.container > div > a > label:nth-of-type(1){color: black;}
.container > div > a > label:nth-of-type(2){color: cornflowerblue;}
.types > button, .form > input[type=submit], .add{border-style: none;padding: 8px 12px;color: white;background: lightgray;border: 1px solid white;}
.types > button.active, .form > input[type=submit], .add{background-color: red;}
.form{text-align: left;}
.form.report{display: flex;flex-direction: column;gap: 8px;}
.form > div{display: flex; flex-direction: column;}
.form > div > input[type=text],.form > select,.form > input[type=text]{padding: 8px 12px;}

.report-group{display: flex;flex-direction: row;gap: 16px; align-items: center;}
.report-group > p{align-self: flex-start;}
.report-group > .form > input[type=text]{width: 30px;text-align: center;margin-right: 4px;}

