.loading-screen{position: fixed;display: flex;justify-content: center;width: 100vw;height: 100vh;top: 0px;background-color: white;align-items: center;}
.form-container{width: 60%;margin-left: 20%;margin-top: 32px;}
.form-container > div.padding > div, .orphan-section > div > div, .widow-section > div > div, .donor-contributions > div > div, .pledge > div{display: flex;flex-direction: column;}
.orphan-section, .widow-section{border-bottom: 1px solid grey;}
.orphan-section > div > h3, .widow-section > div > h3, .donor-contributions > div > h3{text-align: left;margin: 0px;}
.orphan-section > div, .widow-section > div, .donor-contributions > div {border-top: 1px solid gray;margin-top: 0px;padding: 24px 0px;}
.orphan-section > button.add-orphan:nth-of-type(1), .widow-section > button.add-widow:nth-of-type(1), .donor-contributions > button.add-contribution:nth-of-type(1), .form-container > div > div.add-pledge > button:nth-of-type(1) {background-color: rgba(34,139,34,0.2);border-style: none;border: 1px solid forestgreen;color: forestgreen;padding:8px 12px;font-size: 14px;border-radius: 5px;letter-spacing: 0.8px; font-weight: bold;margin:12px 0px;width: 100%;}
.orphan-section > div:last-of-type, .widow-section > div:last-of-type, .donor-contributions > div:last-of-type{border-bottom: 1px solid gray;}
.orphan-section > div > button, .widow-section > div > button, .donor-contributions > div > button,.form-container > div > div > button.remove-pledge:nth-of-type(1) {background-color: rgba(255, 0, 0, 0.2);border-style: none;border: 1px solid red;width: 100%;color: red;padding:8px 12px;margin: 12px 0px 0px 0px;font-size: 14px;border-radius: 5px;letter-spacing: 0.8px; font-weight: bold;}
.form-container > div > div > label, .orphan-section > div > div > label, .widow-section > div > div > label, .donor-contributions > div > div > label, .pledge > div > label{text-align: left;margin-bottom: 8px;font-size: 12px;letter-spacing: 0.5px;}
.form-container > span:nth-of-type(1){color: red;}
.form-container > span:nth-of-type(2){color: forestgreen;}
.form-container > div > div > input,.form-container > div > div > select,.form-container > div > div > button, .orphan-section > div > div > input, .widow-section > div > div > input, .orphan-section > div > div > select, .widow-section > div > div > select, .donor-contributions > div > div > input, .pledge > div > input, .pledge > div > select{padding:8px 12px;margin-bottom: 12px;font-size: 14px;}
.form-container > div > div > button{color: white;font-weight: bold;letter-spacing: 0.8px;border-style: none;border-radius: 5px;}
.form-container > div > div > button:nth-of-type(1){margin-top: 12px;background-color:forestgreen;}
.form-container > div > div > button:nth-of-type(2){background-color: red;}
.orphan-section > h2, .widow-section > h2, .donor-contributions > h2, .donor-contributions  > p{text-align: left;}
.donor-contributions > p{margin: 0px;padding: 16px 0px;}
.donor-contributions > p:nth-of-type(1){border-top: 1px solid grey;}
.donor-contributions > p > label{font-weight: bold;}
.pledges h2, .pledges h3, .pledges h4, .pledges h5, .pledges p{text-align: left;}
.pledges p{margin: 12px 0px 0px 0px;}
.donor-contributions{border-bottom: 1px solid gray;}
button.remove-pledge{margin: 12px 0px 12px 0px;}
.add-pledge{border-bottom: 1px solid gray; border-top: 1px solid gray;margin: 0px 12px;}
.pledge{border: 1px solid gray;border-radius: 5px; margin-bottom: 12px;padding: 0px 12px;padding-bottom: 12px;}
.pledge:nth-of-type(odd){background-color: rgba(255, 0, 0, 0.1);}
.padding{padding: 0px 12px;}
